import React from 'react';
import { Trans } from "gatsby-plugin-react-i18next";
export default function CookiePolicyComponent() {
    return (
        <div className="faq-page">
            <div className="mb-30">
                <h1 className="display-32 pt-32 mb-15"><strong><Trans>cookie.policy.title</Trans></strong></h1>
                <span className="display-12"><Trans>cookie.policy.date</Trans></span>
            </div>
            <div>
                <div className="pt-32">
                    <h3 className="cookie-title  mb-15"><Trans>cookie.policy.1.title</Trans></h3>
                    <span className="cookie-desc"><Trans>cookie.policy.1.description</Trans></span>
                </div>
            </div>
            <div className="pt-32 mb-25">
                <h3 className="cookie-title mb-15"><Trans>cookie.policy.2.title</Trans></h3>
                <span className="cookie-desc mb-15"><Trans>cookie.policy.2.description</Trans></span>
                <div><button id="ot-sdk-btn"
                             className="ot-sdk-show-settings"
                             type="button"><Trans>cookie.policy.button</Trans></button></div>
            </div>
            <div id="ot-sdk-cookie-policy"></div>
        </div>
    )
}
